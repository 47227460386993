<template>
  <div class="wrapper">
    <Header></Header>
    <div class="content" v-if="!$store.state.userInfo">
      <img src="../../assets/img/home/home_img_default@2x.png" alt="" class="default">
      <div class="info">
<!--        <span>上传你珍贵的照片和视频</span>-->
<!--        <span>分享美好时光</span>-->
        <span style="text-align: center" v-html="$t('lang.uploadTips')"></span>
      </div>
      <div class="login" @click="auth(2)">{{ $t('lang.login') }}</div>
    </div>
    <template v-else>
      <div class="banner" v-if="showBanner" @click="showBanner=false">
        <img src="../../assets/img/home/banner_icon_close.png" alt="">
      </div>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh" class="list">
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <div v-for="item in itemList" style="margin-top: 20px">
            <div>{{item.timestamp}}</div>
            <div style="display: flex; flex-wrap: wrap" class="photos">
              <div v-for="(item2, index) in item.imgs" class="photo" @click="previewImage(item.txidKeyArr[index])">
                <van-image :src="item2" fit="cover" alt="" width="100%" height="100%" radius="6" style="cursor: pointer" />
                <img src="../../assets/img/album/img_icon_lock.svg" alt="" v-if="item.encrypt === '1'" class="encrypt">
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </template>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from 'components/header/Header'
import { List, PullRefresh } from 'vant'
import { getMyMetaFileList, getImageMetaFile } from 'api/api'
import  { hexToBase64 } from 'sdk'
function formatDate(value) {
  var date = new Date();
  date.setTime(value);
  var month = date.getMonth() + 1;
  if (month < 10)
    month = "0" + month;
  var dateStr = date.getDate();
  if (dateStr < 10)
    dateStr = "0" + dateStr;
  var hours = date.getHours();
  if (hours < 10)
    hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10)
    minutes = "0" + minutes;

  var time = date.getFullYear() + "-" + month + "-" + dateStr +
      " " + hours + ":" + minutes;
  return date.getFullYear() + month + dateStr;
}
function formatDate2(value) {
  var date = new Date();
  date.setTime(value);
  var month = date.getMonth() + 1;
  if (month < 10)
    month = "0" + month;
  var dateStr = date.getDate();
  if (dateStr < 10)
    dateStr = "0" + dateStr;
  var hours = date.getHours();
  if (hours < 10)
    hours = "0" + hours;
  var minutes = date.getMinutes();
  if (minutes < 10)
    minutes = "0" + minutes;

  var time = date.getFullYear() + "-" + month + "-" + dateStr +
      " " + hours + ":" + minutes;
  return date.getFullYear() + "-" + month + "-" + dateStr;
}
export default {
  name: "Photo",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      itemList: [],
      page: 1,
      pageSize: 10,
      showBanner: true
    }
  },
  components: {
    Header,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  beforeRouteUpdate(to, from, next) {
    next()
  },
  created() {
    // debugger
    // this.onLoad()
  },
  mounted() {
    // debugger
  },
  activated() {
    // debugger
  },
  methods: {
    auth(appType) {
      if (this.$store.state.userInfoLoading) return
      this.$store.state.sdk?.changeSdkType(appType)
      this.$store.state.sdk?.login()
    },
    previewImage(txId) {
      this.$router.push({
        path: this.$route.path + '/view',
        query: {
          address: txId
        }
      })
    },
    onLoad() {
      let params = {
        metaId: localStorage.getItem('showId'),
        page: String(this.page),
        pageSize: String(this.pageSize),
        timestamp: 0,
        fileType: 1 // 1-Image, 2-Video, 3-Text, 4-Other
      }
      getMyMetaFileList(params).then(res => {
        // this.list = res.data.results.items.map(item => {
        //   return {
        //     resUrl: item.resUrl
        //   }
        // })
        var map = new Map();
        res.data.results.items.forEach((item, key) => {
          //if(item.encrypt != "1") {

          if (map.get(formatDate(item.timestamp)) != undefined) {
            var photosimgArr = map.get(formatDate(item.timestamp))
            var photosimg = new Map();
            photosimg.set('encrypt', item.encrypt)
            photosimg.set('txid', `${process.env.VUE_APP_AppImgApi}/metafile/` + item.txId)
            photosimg.set('publicKey', item.publicKey)
            photosimg.set('txidKey', item.txId)
            photosimg.set('timestamp', formatDate2(item.timestamp))
            photosimg.set('timestamp2', formatDate(item.timestamp))

            photosimgArr.push(photosimg)
            map.set(formatDate(item.timestamp), photosimgArr)
          } else {
            var photosimgArr = [];
            var photosimg = new Map();
            photosimg.set('encrypt', item.encrypt)
            photosimg.set('txid', `${process.env.VUE_APP_AppImgApi}/metafile/` + item.txId)
            photosimg.set('publicKey', item.publicKey)
            photosimg.set('txidKey', item.txId)
            photosimg.set('timestamp', formatDate2(item.timestamp))
            photosimg.set('timestamp2', formatDate(item.timestamp))
            photosimgArr.push(photosimg)
            map.set(formatDate(item.timestamp), photosimgArr)
          }


        })
        console.log("所有图片已经格式化")
        console.log(map)
        var timeListTemp = []
        map.forEach(function(key) {
          key.forEach(function(item) {
            if (timeListTemp.length > 0) {
              var isNew = true
              timeListTemp.forEach(function(k, i) {
                if (k['timestamp'] == item.get('timestamp')) {
                  var photosimg = timeListTemp[i]['imgs']
                  var encryptArr = timeListTemp[i]['encryptArr']
                  var publicKeyArr = timeListTemp[i]['publicKeyArr']
                  var txidKeyArr = timeListTemp[i]['txidKeyArr']
                  photosimg.push(item.get('txid'))
                  encryptArr.push(item.get('encrypt'))
                  publicKeyArr.push(item.get('publicKey'))
                  txidKeyArr.push(item.get('txidKey'))
                  var items = {
                    imgs: photosimg,
                    timestamp: timeListTemp[i]['timestamp'],
                    timestamp2: timeListTemp[i]['timestamp2'],
                    encrypt: timeListTemp[i]['encrypt'],
                    encryptArr: encryptArr,
                    publicKeyArr: publicKeyArr,
                    txidKeyArr: txidKeyArr,
                  }
                  timeListTemp[i] = items
                  isNew = false
                }
              })
              if (isNew) {
                var photosimg = []
                var encryptArr = []
                var publicKeyArr = []
                var txidKeyArr = []
                photosimg.push(item.get('txid'))
                encryptArr.push(item.get('encrypt'))
                publicKeyArr.push(item.get('publicKey'))
                txidKeyArr.push(item.get('txidKey'))
                var items = {
                  imgs: photosimg,
                  timestamp: item.get('timestamp'),
                  timestamp2: item.get('timestamp2'),
                  encrypt: item.get('encrypt'),
                  encryptArr: encryptArr,
                  publicKeyArr: publicKeyArr,
                  txidKeyArr: txidKeyArr,
                }
                timeListTemp.push(items)
              }
            } else {
              var photosimg = []
              var encryptArr = []
              var publicKeyArr = []
              var txidKeyArr = []
              photosimg.push(item.get('txid'))
              encryptArr.push(item.get('encrypt'))
              publicKeyArr.push(item.get('publicKey'))
              txidKeyArr.push(item.get('txidKey'))
              var items = {
                imgs: photosimg,
                timestamp: item.get('timestamp'),
                timestamp2: item.get('timestamp2'),
                encrypt: item.get('encrypt'),
                encryptArr: encryptArr,
                publicKeyArr: publicKeyArr,
                txidKeyArr: txidKeyArr,
              }
              timeListTemp.push(items)
            }
          })
        })
        console.log("所有照片初始化完成")
        console.log(timeListTemp)
        //uni.setStorageSync('timeList', timeListTemp)
        this.list = timeListTemp;

        // if (res.data.results.items == null || res.data.results.items.length === 0) {
        //   // 加载结束
        //   this.finished = true;
        //   return;
        // }

        // 将新数据与老数据进行合并
        // this.itemList = this.itemList.concat(this.list);

        if (this.refreshing) {
          this.itemList = [];
          this.refreshing = false;
        }

        if (this.list && this.list.length) {
          this.itemList.push(...this.list);
        }

        this.setDecodeData()

        this.loading = false;
        this.page++
        //如果列表数据条数>=总条数，不再触发滚动加载
        if (this.list.length === 0 || this.itemList.length >= res.data.total) {
          this.finished = true;
        }
      })
    },
    setDecodeData () {
      this.itemList.map((item, listIndex) => {
        if (item.encrypt === '1' && !item.isEncoded) {
          item.isEncoded = true
          item.imgs.map((img, imgIndex) => {
            this.getEncryptData(img).then((res) => {
              if (res) {
                this.$store.state.sdk.eciesDecryptData(res).then((response) => {
                  this.itemList[listIndex].imgs[imgIndex] = hexToBase64(response.data.data)
                  this.$forceUpdate()
                })
              }
            })
          })
        }
      })
    },
    getEncryptData (metafilAddrss) {
      return new Promise(async(resolve, reject) => {
        let p = {
          txIds: [metafilAddrss.slice(-64)]
        }
        await getImageMetaFile(p).then(res => {
          resolve(res.data.results.items[0].data)
        })
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.page = 1;
      this.onLoad();
    }
  }
}
</script>

<style lang="less" scoped>
  .wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-width: 600Px;
    margin: 0 auto;
    .banner {
      background-image: url('../../assets/img/home/banner.png');
      background-position: 0% 0%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 117px;
      position: relative;
      img {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
    }
    .list {
      padding: 0 15px 50px
    }
    .photos {
      //div:nth-child(3n+1) {
      //  margin-left: 0px !important;
      //}
      margin-left: -10px;
      .photo {
        width: 108px;
        height: 108px;
        display: inline-block;
        margin-top: 10px;
        margin-left: 10px;
        position: relative;
        .encrypt {
          position: absolute;
          bottom: 4px;
          right: 4px;
        }
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 140px;
      .default {
        width: 125px;
        height: 104px;
      }
      .info {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: #BFC2CC;
      }
      .login {
        background-color: #617FFF;
        width: 90px;
        height: 30px;
        border-radius: 20px;
        font-size: 12px;
        color: #FFFFFF;
        text-align: center;
        line-height: 30px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
</style>
