var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("Header"),
      !_vm.$store.state.userInfo
        ? _c("div", { staticClass: "content" }, [
            _c("img", {
              staticClass: "default",
              attrs: {
                src: require("../../assets/img/home/home_img_default@2x.png"),
                alt: "",
              },
            }),
            _c("div", { staticClass: "info" }, [
              _c("span", {
                staticStyle: { "text-align": "center" },
                domProps: { innerHTML: _vm._s(_vm.$t("lang.uploadTips")) },
              }),
            ]),
            _c(
              "div",
              {
                staticClass: "login",
                on: {
                  click: function ($event) {
                    return _vm.auth(2)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("lang.login")))]
            ),
          ])
        : [
            _vm.showBanner
              ? _c(
                  "div",
                  {
                    staticClass: "banner",
                    on: {
                      click: function ($event) {
                        _vm.showBanner = false
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/img/home/banner_icon_close.png"),
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
            _c(
              "van-pull-refresh",
              {
                staticClass: "list",
                on: { refresh: _vm.onRefresh },
                model: {
                  value: _vm.refreshing,
                  callback: function ($$v) {
                    _vm.refreshing = $$v
                  },
                  expression: "refreshing",
                },
              },
              [
                _c(
                  "van-list",
                  {
                    attrs: {
                      finished: _vm.finished,
                      "finished-text": "没有更多了",
                    },
                    on: { load: _vm.onLoad },
                    model: {
                      value: _vm.loading,
                      callback: function ($$v) {
                        _vm.loading = $$v
                      },
                      expression: "loading",
                    },
                  },
                  _vm._l(_vm.itemList, function (item) {
                    return _c(
                      "div",
                      { staticStyle: { "margin-top": "20px" } },
                      [
                        _c("div", [_vm._v(_vm._s(item.timestamp))]),
                        _c(
                          "div",
                          {
                            staticClass: "photos",
                            staticStyle: {
                              display: "flex",
                              "flex-wrap": "wrap",
                            },
                          },
                          _vm._l(item.imgs, function (item2, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "photo",
                                on: {
                                  click: function ($event) {
                                    return _vm.previewImage(
                                      item.txidKeyArr[index]
                                    )
                                  },
                                },
                              },
                              [
                                _c("van-image", {
                                  staticStyle: { cursor: "pointer" },
                                  attrs: {
                                    src: item2,
                                    fit: "cover",
                                    alt: "",
                                    width: "100%",
                                    height: "100%",
                                    radius: "6",
                                  },
                                }),
                                item.encrypt === "1"
                                  ? _c("img", {
                                      staticClass: "encrypt",
                                      attrs: {
                                        src: require("../../assets/img/album/img_icon_lock.svg"),
                                        alt: "",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ],
      _c("router-view"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }